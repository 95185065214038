import EventsPage from "../components/EventsPage/EventsPage.jsx";

const propTypes = {};

const AboutUs = (props) => {
  return <EventsPage />;
};

AboutUs.propTypes = propTypes;
export default AboutUs;
